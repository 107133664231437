import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ol>
      <li parentName="ol">{`L'excès de poids peut entraîner de graves problèmes de santé.`}</li>
      <li parentName="ol">{`L'excès de poids peut diminuer votre espérance de vie.`}</li>
      <li parentName="ol">{`L'excès de poids peut causer des difficultés physiques et/ou mobilité réduite.`}</li>
      <li parentName="ol">{`L'excès de poids peut augmenter votre risque de maladies cardiaques.`}</li>
      <li parentName="ol">{`L'excès de poids peut augmenter votre risque de diabète.`}</li>
      <li parentName="ol">{`L'excès de poids peut augmenter votre risque de certains types de cancer.`}</li>
      <li parentName="ol">{`L'excès de poids peut causer des problèmes de respiration.`}</li>
      <li parentName="ol">{`L'excès de poids peut entraîner une fatigue excessive.`}</li>
    </ol>
    <p>{`Il y a de nombreuses raisons de vouloir perdre du poids, et ces 8 raisons en sont que quelques-unes. Si vous êtes en surpoids, il est important de prendre des mesures pour perdre du poids de façon saine et équilibrée. En plus de vous aider à améliorer votre santé, perdre du poids peut également vous aider à vous sentir mieux dans votre corps et à augmenter votre confiance en vous.`}</p>
    <p>{`Si vous êtes intéressé à perdre du poids, il y a de nombreuses façons de s'y prendre. En suivant un régime alimentaire sain et équilibré, en faisant de l'exercice régulièrement et en adoptant de saines habitudes de vie, vous pouvez perdre du poids de façon saine et durable. Parlez à votre médecin ou à un professionnel de la santé pour obtenir plus d'informations sur la perte de poids et comment vous y prendre.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      